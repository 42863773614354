import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import "./DataTable.css";
import { Link } from "react-router-dom";

const DataTable = (props) => {
  const { header, data_arr, data_reports_arr } = props;
  return (
    <>
      <TableContainer>
        <Table className="table" aria-label="dynamic table">
          <TableHead>
            <TableRow>
              {header &&
                header.map((i, index) => (
                  <TableCell className="header" key={index}>
                    {i}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data_arr && data_arr?.length > 0
              ? data_arr.map((i, index) => (
                  <TableRow key={index}>
                    <TableCell>{i.id}</TableCell>
                    <TableCell>{i.transaction_id}</TableCell>
                    <TableCell>
                      {new Date(i.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>{i.transaction_status}</TableCell>
                    <TableCell className="transaction-amount">
                      <span
                        style={{
                          color:
                            i.withdraw_amount <= 1500
                              ? "#F00"
                              : i.withdraw_amount > 1000
                              ? "#25B467"
                              : null,
                        }}
                      >
                        ₹{i.withdraw_amount}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              : null}

            {data_reports_arr && data_reports_arr?.length > 0
              ? data_reports_arr.map((i, index) => (
                  <TableRow
                    className="reports-table"
                    style={{
                      backgroundColor:
                        i.display_order_item_statuses === "Delivered"
                          ? "#E0FFEE"
                          : i.display_order_item_statuses === "Order Created"
                          ? "#F3F5F6"
                          : i.display_order_item_statuses === "Cancel"
                          ? "#FFE0E0"
                          : "",
                    }}
                    key={index}
                  >
                    <TableCell>{i.id}</TableCell>
                    <TableCell>{i.order_id}</TableCell>
                    <TableCell>
                      <Link className="product-group" to={`/`}>
                        <img
                          src={i?.product?.product_sub_grp?.front_img_url}
                          alt=""
                        />
                        <p>{i?.product?.title}</p>
                      </Link>
                    </TableCell>
                    <TableCell>{i?.product?.product_cat?.name}</TableCell>
                    <TableCell>₹{i.price}</TableCell>
                    <TableCell>{i.qty}</TableCell>
                    <TableCell>
                      {i?.product?.product_cat?.affiliate_percentage}%
                    </TableCell>
                    <TableCell>{i.display_order_item_statuses}</TableCell>
                    <TableCell>{i.earning}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DataTable;
