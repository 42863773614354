import React, { useEffect, useState } from "react";
import MetaData from "../../Components/MetaData/MetaData";
import SideBar from "../../Components/SideBar/SideBar";
import "./Dashboard.css";
import HeaderText from "../../Components/HeaderText/HeaderText";
import Navbar from "../../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { get_dashboard_data_Actions } from "../../Actions/Actions";
import Loader from "../../Components/Loader/Loader";
import BarChart from "../../Components/BarChart/BarChart";
import Dropdown from "../../Components/Dropdown/Dropdown";
import NoDataFound from "../../Components/Loader/NoDataFound";

const Dashboard = () => {
  const dispatch = useDispatch();

  // get dashboard_data_metrics
  const { dashboard_metrics, loading } = useSelector(
    (state) => state.dashboardMetrics
  );

  const date_arr = [
    {
      id: "today",
      value: "Today",
      label: "Today",
    },
    {
      id: "yesterday",
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      id: "this_month",
      value: "This Month",
      label: "This Month",
    },
    {
      id: "last_7_days",
      value: "Last 7 Days",
      label: "Last 7 Days",
    },
    {
      id: "last_3_months",
      value: "Last 3 Months",
      label: "Last 3 Months",
    },
    {
      id: "last_6_months",
      value: "Last 6 Months",
      label: "Last 6 Months",
    },
  ];

  const metrics_data = [
    {
      count: dashboard_metrics && dashboard_metrics?.data?.clicks,
      title: "Traffic",
      bgColor: "rgba(215, 229, 215, 1)",
    },
    {
      count: dashboard_metrics && dashboard_metrics?.data?.orders,
      title: "Ordered",
      bgColor: "rgba(219, 196, 240, 1)",
    },
    {
      count: dashboard_metrics && dashboard_metrics?.data?.shipped,
      title: "Shipped",
      bgColor: "rgba(251, 226, 179, 1)",
    },
    {
      count: dashboard_metrics && dashboard_metrics?.data?.conversion + "%",
      title: "Conversion",
      bgColor: "rgba(255, 178, 178, 1)",
    },
    {
      count: dashboard_metrics && dashboard_metrics?.data?.returned,
      title: "Returned",
      bgColor: "rgba(210, 224, 251, 1)",
    },
    {
      count: dashboard_metrics && dashboard_metrics?.data?.cancel,
      title: "Cancel",
      bgColor: "rgba(255, 216, 156, 1)",
    },
    {
      count: "₹" + dashboard_metrics && dashboard_metrics?.data?.delivered,
      title: "Delivered",
      bgColor: "rgba(253, 206, 223, 1)",
    },
    {
      count: "₹" + dashboard_metrics && dashboard_metrics?.data?.total_earning,
      title: "Total Earning",
      bgColor: "rgba(210, 233, 233, 1)",
    },
  ];

  const [new_filter, setNew_Filter] = useState(date_arr && date_arr[0].value);
  const [filter, setFilter] = useState(date_arr && date_arr[0].id);

  const handleDateChange = (val) => {
    setFilter(val?.id);
    setNew_Filter(val?.value);
    console.log(val);
  };

  useEffect(() => {
    dispatch(get_dashboard_data_Actions(filter));
  }, [dispatch, filter]);

  console.log("Dashboard Metrics: ", dashboard_metrics?.data?.traffic_overview);

  return (
    <>
      <MetaData title={`Dashboard - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div className="header-dropdown-text-container">
                <HeaderText title="Dashboard" select={true} />
                <Dropdown
                  onData={handleDateChange}
                  value={new_filter}
                  dropdown={date_arr}
                  dateT={true}
                  placeholder={"Choose Date"}
                />
              </div>
              {/* header component ends here */}

              {/* metrics data component starts here */}
              <div className="metrics-grid-row-container">
                {metrics_data &&
                  metrics_data.map((i, index) => (
                    <div style={{ backgroundColor: i.bgColor }} key={index}>
                      <p>
                        {typeof i.count === "number"
                          ? i.count.toFixed(1)
                          : i.count}
                      </p>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>
              {/* metrics_data component ends here */}

              {/* traffic overview component starts here */}
              <div className="traffic-overview-container">
                <p>Statistics</p>
                <h3>Traffic Overview</h3>
                {dashboard_metrics &&
                dashboard_metrics?.data?.traffic_overview?.length > 0 ? (
                  <BarChart
                    traffic_overview={
                      dashboard_metrics &&
                      dashboard_metrics?.data?.traffic_overview?.length > 0
                        ? dashboard_metrics?.data?.traffic_overview
                        : []
                    }
                  />
                ) : (
                  <NoDataFound
                    top={true}
                    title={
                      <>
                        No Data Found Try different <br /> combination of
                        Filters
                      </>
                    }
                  />
                )}
              </div>
              {/* traffic overview component ends here */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
