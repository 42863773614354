import React, { useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/MetaData/MetaData";
import Navbar from "../../Components/Navbar/Navbar";
import HeaderText from "../../Components/HeaderText/HeaderText";
import "./Help.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";

const faqs_arr = [
  {
    title: "Help Title - 1",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 2",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 3",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 4",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 5",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 6",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 7",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 8",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 9",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
  {
    title: "Help Title - 10",
    desc: "You can share products and available programs on Amazon with your audience through customized linking tools and earn money on qualifying purchases and customer actions like signing up for a free trial program.",
  },
];

const Help = () => {
  const [isIndex, setIsIndex] = useState([]);

  const handleToggle = (i) => {
    if (isIndex.includes(i)) {
      let filteredIndex = isIndex.filter((x) => x !== i);
      setIsIndex(filteredIndex);
    } else {
      setIsIndex((prev) => [...prev, i]);
    }
  };

  console.log(isIndex);

  return (
    <>
      <MetaData title={`Help - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          <div className="container-wrapper">
            {/* header component starts here */}
            <HeaderText
              title="Help"
              description="In the report section, access a comprehensive summary displaying your earnings, commissions, product-specific growth, demand trends, and more. Gain insights into your performance and explore valuable details to optimize your strategies."
            />
            {/* header component ends here */}

            {/* Accordion component starts here */}
            {faqs_arr && faqs_arr.length > 0
              ? faqs_arr.map((i, index) => (
                  <div key={index}>
                    <div
                      className="container-accordion"
                      onClick={() => handleToggle(index)}
                    >
                      <div>
                        <h3>{i.title}</h3>
                        <div className="icon-accordion">
                          <ChevronLeftOutlinedIcon
                            className={`${
                              !isIndex.includes(index)
                                ? "close-icon"
                                : "open-icon"
                            }`}
                          />
                        </div>
                      </div>
                      <div
                        className={`animate-div ${
                          !isIndex.includes(index) ? "open" : "closed"
                        }`}
                      >
                        {!isIndex.includes(index) && <p>{i.desc}</p>}
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {/* Accordion component ends here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
