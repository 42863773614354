import React, { useEffect, useRef, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { get_Product_list_data_Actions } from "../../../Actions/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import api from "../../../Api";
import { BASE_URL } from "../../../BaseURL";
import AnimatedLoader from "../../Loader/AnimatedLoader";
import { Button } from "@mui/material";
import "./AddProducts.css";
import { useAlert } from "react-alert";

const AddProducts = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { product_list_data, loading } = useSelector(
    (state) => state.productListData
  );
  const { close, categories_id, getArrImages, getIds } = props;

  const [hasMore, setHasMore] = useState(true);

  const [product_list_arr, setProduct_list_arr] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(2);
  const pageRef = useRef(page);

  const [isActive, setIsActive] = useState(false);

  console.log("Categories Id: ", categories_id);

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    if (!isActive) {
      dispatch(get_Product_list_data_Actions(categories_id, 1));
    }
  }, [dispatch, categories_id, isActive]);

  useEffect(() => {
    product_list_data &&
      product_list_data?.data &&
      setProduct_list_arr(product_list_data?.data?.data);
  }, [product_list_data]);

  console.log("Product List: ", product_list_data?.data?.data);

  const handleNext = () => {
    console.log("pk ok");
    setIsActive(true);
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    setIsLoading(true);
    api
      .post(`${BASE_URL}/product-list?page=${page}`, {
        category_id: categories_id,
      })
      .then((response) => {
        console.log("nxx", response?.data?.data);
        const newProducts = response?.data?.data?.data;
        if (newProducts.length === 0) {
          setHasMore(false);
          // setIsActive(false);
        } else {
          // Combine new products with the existing ones
          setProduct_list_arr((prevList) => {
            // Check if prevList is an array before using map
            const existingIds = Array.isArray(prevList)
              ? prevList.map((product) => product.id)
              : [];

            const filteredNewProducts = newProducts.filter(
              (product) => !existingIds.includes(product.id)
            );

            return Array.isArray(prevList)
              ? [...prevList, ...filteredNewProducts]
              : filteredNewProducts;
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsActive(false);
        alert.error(err.message);
        setIsLoading(false);
      });
  };

  const [sub_group_ids, setsub_group_ids] = useState([]);

  const [img_list, setImg_list] = useState([]);

  const handleAdd = (i, img) => {
    if (sub_group_ids.includes(i) || img_list.includes(img.id)) {
      let filter = sub_group_ids.filter((x) => x !== i);
      setsub_group_ids(filter);

      let filter_img = img_list.filter((y) => y !== img.front_img_url);
      setImg_list(filter_img);
    } else {
      setsub_group_ids((prev) => [...prev, i]);
      setImg_list((prev) => [...prev, img.front_img_url]);
    }
  };

  const handleImg = () => {
    getArrImages(img_list);
    getIds(sub_group_ids);
    close();
  };

  console.log("SubGroup Ids: ", sub_group_ids);
  console.log("Image List: ", img_list);

  return (
    <div className="header-add-product">
      <HeadingTitle
        title={
          sub_group_ids?.length > 0 ? (
            <Button onClick={handleImg} className="button-product">
              Submit
            </Button>
          ) : (
            "Add Products"
          )
        }
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={handleClose}
      />
      {loading && !isActive ? (
        <AnimatedLoader top={true} />
      ) : (
        <InfiniteScroll
          dataLength={product_list_arr && product_list_arr?.length}
          next={handleNext}
          hasMore={hasMore}
          height={380}
        >
          <div className="top-container">
            {product_list_arr &&
              product_list_arr?.map((i) => (
                <div className="product-grid-container-row" key={i.id}>
                  <div>
                    <img src={i.front_img_url} alt="" />
                    <div>
                      <p>{i.name}</p>
                      <p>20K Sold</p>
                    </div>
                  </div>
                  <div>
                    <Button style={{backgroundColor: sub_group_ids.includes(i.id) ? "#62DE9B" : "#5C5C5C"}} onClick={() => handleAdd(i.id, i)}>
                      {sub_group_ids.includes(i.id) ? "Unselect" : "Select"}
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </InfiniteScroll>
      )}
      {/* Loader */}
      <div>{isLoading ? <AnimatedLoader /> : null}</div>
      {/* Loader */}
      {/* <div className="button-product">
        <Button>Submit</Button>
      </div> */}
    </div>
  );
};

export default AddProducts;
