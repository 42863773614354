import React, { useEffect, useRef, useState } from "react";
import MetaData from "../../Components/MetaData/MetaData";
import SideBar from "../../Components/SideBar/SideBar";
import Navbar from "../../Components/Navbar/Navbar";
import HeaderText from "../../Components/HeaderText/HeaderText";
import ProductList from "../../Components/ProductList/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { get_Commission_product_list__Actions } from "../../Actions/Actions";
import { BASE_URL } from "../../BaseURL";
import api from "../../Api";
import AnimatedLoader from "../../Components/Loader/AnimatedLoader";
import Loader from "../../Components/Loader/Loader";

// const Commission_product_arr = [
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/1.png"),
//     title: "Party Wear",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/2.png"),
//     title: "Oversized",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/3.png"),
//     title: "Full Sleeves",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/4.png"),
//     title: "Half Sleeves",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/5.png"),
//     title: "Simple T-Shirts",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/6.png"),
//     title: "Printed T-Shirt",
//     commission: "5% Commission",
//   },
//   {
//     img: require("../../Assets/Images/IdeaHub_Img/9.png"),
//     title: "Polo T-Shirts",
//     commission: "5% Commission",
//   },
// ];
const Commission = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // ideahub_product_list state
  const { commission_product, loading } = useSelector(
    (state) => state.commisionProductList
  );

  const [page, setPage] = useState(2);

  const [commision_product_list, setCommision_product_list] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const pageRef = useRef(page);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      dispatch(get_Commission_product_list__Actions(1));
    }
  }, [dispatch, isActive]);

  console.log("Commission Product List: ", commission_product);

  useEffect(() => {
    if (!isActive) {
      commission_product &&
        setCommision_product_list(
          commission_product &&
            Array.isArray(commission_product?.data?.data) &&
            commission_product?.data?.data
        );
    }
  }, [commission_product, isActive]);

  const handleNext = () => {
    setIsActive(true);
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    setIsLoading(true);
    api
      .post(`${BASE_URL}/product-commission?page=${page}`)
      .then((response) => {
        const newProducts = response?.data?.data?.data;
        if (newProducts.length === 0) {
          setHasMore(false);
          // setIsActive(false);
        } else {
          // Combine new products with the existing ones
          setCommision_product_list((prevList) => {
            // Check if prevList is an array before using map
            const existingIds = Array.isArray(prevList)
              ? prevList.map((product) => product.id)
              : [];

            const filteredNewProducts = newProducts.filter(
              (product) => !existingIds.includes(product.id)
            );

            return Array.isArray(prevList)
              ? [...prevList, ...filteredNewProducts]
              : filteredNewProducts;
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsActive(false);
        alert.error(err.message);
        setIsLoading(false);
      });
  };
  return (
    <>
      <MetaData title={`Commission - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          {loading && !isActive ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderText
                title="Commission"
                description="Referral Payouts: Earn rewards by referring customers in November 2023. Benefit from our payout system for each order generated through your referrals this month."
              />
              {/* header component ends here */}

              <ProductList
                handleNext={handleNext}
                data={commision_product_list && commision_product_list}
                hasMore={hasMore}
                commission={true}
              />

              {/* Loader */}
              <div className="loader-container-product">
                {isLoading ? <AnimatedLoader /> : null}
              </div>
              {/* Loader */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Commission;
