import React, { useCallback, useEffect, useState } from "react";
import "./SideBar.css";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "./SideBarArray.js";
import Logo from '../../Assets/Logo/logo_image.svg'
import { FaAlignLeft, FaRegTimesCircle } from "react-icons/fa";

const SideBar = () => {
  const location = useLocation();
  let location_path = location.pathname;
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const toggleNavbar = () => {
    setIsSideBarVisible(!isSideBarVisible);
  };
  

  useEffect(() => {
    if (window.innerWidth <= 1080) {
      setIsSideBarVisible(false);
    } else {
      setIsSideBarVisible(true);
    }
  }, []);

  const [activeLink, setActiveLink] = useState(null);

  const handleMouseEnter = useCallback((index) => {
    setActiveLink(index);
  }, []);

  const handleMouseLeave = () => {
    if (location_path.startsWith("/style-sets")) {
      setActiveLink(3);
    } else {
      setActiveLink(null);
    }
  };

  useEffect(() => {
    if (location_path.startsWith("/style-sets")) {
      handleMouseEnter(3);
    }
  }, [location_path, handleMouseEnter]);

  console.log("Location: ", location_path);

  return (
    <>
      {isSideBarVisible ? (
        <FaRegTimesCircle className="menu-icon" onClick={toggleNavbar} />
      ) : (
        <FaAlignLeft className="menu-icon" onClick={toggleNavbar} />
      )}
      <div
        className={`sidebar-menu ${isSideBarVisible ? "visible" : "hidden"}`}
      >
        <div className="logo">
         <img src={Logo} alt="" />
        </div>
        <div className="routes">
          {routes &&
            routes.map((r, index) => {
              return (
                <div key={r.title}>
                  <div className="menu-wrapper">
                    <NavLink
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      activeclassname="active"
                      className="menu-item"
                      to={r.path}
                    >
                      <img
                        src={
                          activeLink === index || location_path === r.path
                            ? r.iconColor
                            : r.icon
                        }
                        alt=""
                      />
                      <p>{r.title}</p>
                    </NavLink>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SideBar;
