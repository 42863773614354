import api from "../Api";
import { BASE_URL } from "../BaseURL";
import {
  CREATE_AFFILIATE_USER_FAILURE,
  CREATE_AFFILIATE_USER_REQUEST,
  CREATE_AFFILIATE_USER_SUCCESS,
  CREATE_BANK_DETAILS_FAILURE,
  CREATE_BANK_DETAILS_REQUEST,
  CREATE_BANK_DETAILS_SUCCESS,
  CREATE_PRODUCT_LINK_FAILURE,
  CREATE_PRODUCT_LINK_REQUEST,
  CREATE_PRODUCT_LINK_SUCCESS,
  CREATE_UPI_DETAILS_FAILURE,
  CREATE_UPI_DETAILS_REQUEST,
  CREATE_UPI_DETAILS_SUCCESS,
  GET_ALL_STATE_LIST_FAILURE,
  GET_ALL_STATE_LIST_REQUEST,
  GET_ALL_STATE_LIST_SUCCESS,
  GET_BANK_UPI_DETAILS_FAILURE,
  GET_BANK_UPI_DETAILS_REQUEST,
  GET_BANK_UPI_DETAILS_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_COMMISSION_PRODUCT_LIST_FAILURE,
  GET_COMMISSION_PRODUCT_LIST_REQUEST,
  GET_COMMISSION_PRODUCT_LIST_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_IDEA_HUB_PRODUCT_LIST_FAILURE,
  GET_IDEA_HUB_PRODUCT_LIST_REQUEST,
  GET_IDEA_HUB_PRODUCT_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_REPORTS_LIST_FAILURE,
  GET_REPORTS_LIST_REQUEST,
  GET_REPORTS_LIST_SUCCESS,
  GET_TRANSACTION_LIST_FAILURE,
  GET_TRANSACTION_LIST_REQUEST,
  GET_TRANSACTION_LIST_SUCCESS,
  WITHDRAWL_AMOUNT_FAILURE,
  WITHDRAWL_AMOUNT_REQUEST,
  WITHDRAWL_AMOUNT_SUCCESS,
} from "../Constants/Constants";

// create_affiliate_user actions
export const Create_affiliate_user_Actions =
  (
    first_name,
    last_name,
    address1,
    address2,
    city,
    state,
    pincode,
    phone,
    email
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_AFFILIATE_USER_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/user-create`, {
        first_name,
        last_name,
        address1,
        address2,
        city,
        state,
        pincode,
        phone,
        email,
      });
      dispatch({
        type: CREATE_AFFILIATE_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_AFFILIATE_USER_FAILURE,
        payload: error.message,
      });
    }
  };

// get_all_state_list actions
export const get_All_state_list_Actions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_STATE_LIST_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/state-list`);
    dispatch({
      type: GET_ALL_STATE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_STATE_LIST_FAILURE,
      payload: error.message,
    });
  }
};

// get_bank_upi_list actions
export const get_bank_upi_details_Actions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BANK_UPI_DETAILS_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/user-bank-list`);
    dispatch({
      type: GET_BANK_UPI_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BANK_UPI_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

// create_bank_data actions
export const createBank_details_Actions =
  (bank_name, bank_user_name, ifsc_code, account_number) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_BANK_DETAILS_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/user-bank-create`, {
        bank_name,
        bank_user_name,
        ifsc_code,
        account_number,
      });
      dispatch({
        type: CREATE_BANK_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_BANK_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

// create_upi_data actions
export const createUPI_details_Actions = (upi_id) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_UPI_DETAILS_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/user-bank-create`, { upi_id });
    dispatch({
      type: CREATE_UPI_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_UPI_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

// get_dashboard_data actions
export const get_dashboard_data_Actions = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/dashboard-data`, { filter });
    dispatch({
      type: GET_DASHBOARD_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_DATA_FAILURE,
      payload: error.message,
    });
  }
};

// Get_Ideahub_product_list actions
export const get_Idea_hub_product_list__Actions =
  (page, item_per_page, tag) => async (dispatch) => {
    try {
      dispatch({
        type: GET_IDEA_HUB_PRODUCT_LIST_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/idea-hub?page=${page}`, {
        item_per_page,
        tag,
      });
      dispatch({
        type: GET_IDEA_HUB_PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_IDEA_HUB_PRODUCT_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

// Get_Commission_product_list actions
export const get_Commission_product_list__Actions =
  (page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COMMISSION_PRODUCT_LIST_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/product-commission?page=${page}`
      );
      dispatch({
        type: GET_COMMISSION_PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMMISSION_PRODUCT_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

// Get_transaction_list actions
export const get_Transaction_list__Actions = (page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTION_LIST_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/transactions?page=${page}`);
    dispatch({
      type: GET_TRANSACTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANSACTION_LIST_FAILURE,
      payload: error.message,
    });
  }
};

// Get_Reports_list actions
export const get_Reports_list__Actions = (page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORTS_LIST_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/reports?page=${page}`);
    dispatch({
      type: GET_REPORTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_REPORTS_LIST_FAILURE,
      payload: error.message,
    });
  }
};

// Create_product_link actions
export const create_product_link__Actions = (type, url) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PRODUCT_LINK_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/create-product-url`, {
      type,
      url,
    });
    dispatch({
      type: CREATE_PRODUCT_LINK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PRODUCT_LINK_FAILURE,
      payload: error.message,
    });
  }
};

// Create_product_link actions(2)_with_name
export const create_product_link_url_name__Actions =
  (type, url, url_name) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PRODUCT_LINK_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/create-product-url`, {
        type,
        url,
        url_name,
      });
      dispatch({
        type: CREATE_PRODUCT_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_LINK_FAILURE,
        payload: error.message,
      });
    }
  };

// Create_product_link actions(3)_with_name_&ids
export const create_product_link_url_name_ids__Actions =
  (type, url_name, sub_group_ids) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PRODUCT_LINK_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/create-product-url`, {
        type,
        url_name,
        sub_group_ids,
      });
      dispatch({
        type: CREATE_PRODUCT_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_LINK_FAILURE,
        payload: error.message,
      });
    }
  };

// Create_product_link actions(4)_with_name_&ids
export const create_product_link_url_name_subgroup_ids__Actions =
  (type, url_name, sub_group_ids) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PRODUCT_LINK_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/create-product-url`, {
        type,
        url_name,
        sub_group_ids,
      });
      dispatch({
        type: CREATE_PRODUCT_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_LINK_FAILURE,
        payload: error.message,
      });
    }
  };

// Create_product_link actions
export const get_Product_Info__Actions = (type, url) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/get-product`, {
      type,
      url,
    });
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_FAILURE,
      payload: error.message,
    });
  }
};

// withdrawl_amount actions
export const withdraw_amount__Actions =
  (withdraw_amount, bank_id) => async (dispatch) => {
    try {
      dispatch({
        type: WITHDRAWL_AMOUNT_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/withdraw-amount`, {
        withdraw_amount,
        bank_id,
      });
      dispatch({
        type: WITHDRAWL_AMOUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WITHDRAWL_AMOUNT_FAILURE,
        payload: error.message,
      });
    }
  };

// get_All_Categories actions
export const get_All_Categories__Actions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORIES_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/get-categories`);
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_FAILURE,
      payload: error.message,
    });
  }
};

// get_Product_list_data actions
export const get_Product_list_data_Actions =
  (category_id, page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PRODUCTS_LIST_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/product-list?page=${page}`, {
        category_id,
      });
      dispatch({
        type: GET_PRODUCTS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_LIST_FAILURE,
        payload: error.message,
      });
    }
  };
