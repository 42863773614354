import React from "react";
import NoDataFoundIcon from "../../Assets/Images/Loader/no-result-found.gif";

const NoDataFound = ({ title,top }) => {
  return (
    <div style={{marginTop: top && "-6rem"}} className="no-data-found">
      <img src={NoDataFoundIcon} alt="" />
      <p>{title}</p>
    </div>
  );
};

export default NoDataFound;
