import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import {
  Create_affiliate_user_Reducers,
  createBank_details_Reducers,
  createUPI_details_Reducers,
  create_product_link_Reducers,
  get_Idea_hub_product_list_Reducers,
  get_Product_Reducers,
  get_Products_list_Reducers,
  get_Reports_list_Reducers,
  get_State_List_Reducers,
  get_Transactions_list_Reducers,
  get_bank_upi_details_Reducers,
  get_categories_Reducers,
  get_commision_product_list_Reducers,
  get_dashboard_data_Reducers,
  withdraw_amount_Reducers,
} from "./Reducers/Reducers";

const reducer = combineReducers({
  createaffiliateuser: Create_affiliate_user_Reducers,
  stateList: get_State_List_Reducers,
  bank_UPI_details: get_bank_upi_details_Reducers,
  createBankData: createBank_details_Reducers,
  createUpiData: createUPI_details_Reducers,
  dashboardMetrics: get_dashboard_data_Reducers,
  ideaHubProductList: get_Idea_hub_product_list_Reducers,
  commisionProductList: get_commision_product_list_Reducers,
  transactionList: get_Transactions_list_Reducers,
  reportsList: get_Reports_list_Reducers,
  productLink: create_product_link_Reducers,
  getProductInfo: get_Product_Reducers,
  withdrawlAmount: withdraw_amount_Reducers,
  getAllCategories: get_categories_Reducers,
  productListData: get_Products_list_Reducers
});

const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
