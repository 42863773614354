import React from "react";
import "./Pagination.css";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Button } from "@mui/material";
import CustomLoader from "../Loader/CustomLoader";

export const Pagination = ({
  page,
  isLoading,
  totalPage,
  onPageChange,
  uniqueKey,
}) => {
  const handlePageClick = useCallback(
    (page) => {
      onPageChange(page);
    },
    [onPageChange]
  );

  let maxvisiblePages = 5;

  const [visiblePages, setVisiblePage] = useState([]);

  const renderPagination = useCallback(() => {
    const totalPages = Math.ceil(totalPage);
    const pageOffset = Math.floor(maxvisiblePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxvisiblePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxvisiblePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={`${uniqueKey}-page-${i}`}
          onClick={() => handlePageClick(i)}
          id={i}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <CustomLoader /> : i}
        </Button>
      );
    }

    setVisiblePage(pageNumbers);

    return pageNumbers;
  }, [handlePageClick, isLoading, maxvisiblePages, page, totalPage, uniqueKey]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  return <div key={uniqueKey}>{visiblePages}</div>;
};
