import React, { useState } from "react";
import "./ProductList.css";
import StarIcon from "@mui/icons-material/Star";
import { Button } from "@mui/material";
import LinkIcon from "../../Assets/Sidebar_Logo/link-square.png";
import ModalContainer from "../ModalContainer/ModalContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import { DEFAULT_IMG } from "../../BaseURL";

const ProductList = ({ data, commission, handleNext, hasMore }) => {
  const [isIndex, setIsIndex] = useState(null);
  let type = "product";
  const [url, setUrl] = useState("");
  const handleModal = (index, uri) => {
    setUrl(uri);
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          type={type}
          url={url}
        />
      );
    }
    return null;
  };

  return (
    <InfiniteScroll
      dataLength={data && data?.length}
      next={handleNext}
      hasMore={hasMore}
    >
      <div className="product-container">
        <div className="product-list-grid-row">
          {data &&
            data.map((i, index) => (
              <div key={index}>
                {/* img row */}
                <div className="product-img">
                  <div className="overlay"></div>
                  {commission ? null : (
                    <div className="trending-tag">
                      <h4>Trending</h4>
                    </div>
                  )}
                  {commission ? null : (
                    <div className="rating-tag">
                      <h4>
                        {i?.avg_rating?.rating} <StarIcon /> | &nbsp;
                        {i?.avg_rating?.count}
                      </h4>
                    </div>
                  )}
                  {commission ? null : <p>{i.overlayTitle}</p>}
                  <img
                    onError={(e) => (e.target.src = DEFAULT_IMG)}
                    src={i.img}
                    alt=""
                  />
                </div>
                {/* img row */}
                <div className="product-desc">
                  {commission ? (
                    <>
                      <h4>
                        {i.name
                          .toLowerCase()
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </h4>
                      <h3>{i.affiliate_percentage}% Commission</h3>
                    </>
                  ) : (
                    <>
                      <p className="tile-name">
                        {i.name
                          .toLowerCase()
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                      {/* <p>
                      {i.price} &nbsp;<span>{i.textOver_Line}</span>
                    </p> */}
                      <Button
                        className="link-get"
                        onClick={() => handleModal(1, i.slug)}
                      >
                        <img src={LinkIcon} alt="" />
                        &nbsp; Get Link
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
        {renderModal()}
      </div>
    </InfiniteScroll>
  );
};

export default ProductList;
