import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../Loader/CustomLoader";
import { createUPI_details_Actions } from "../../../Actions/Actions";
import { useAlert } from "react-alert";

const AddUPIModal = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // create_upi_state
  const { create_UPI, loading } = useSelector((state) => state.createUpiData);

  const [added, setAdded] = useState(false);

  const { close } = props;

  const [upi_id, setUpi_id] = useState("");

  const handleAddUPI = (e) => {
    e.preventDefault();
    setAdded(true);
    dispatch(createUPI_details_Actions(upi_id));
  };

  useEffect(() => {
    if (create_UPI && create_UPI?.success === true && added) {
      alert.success(create_UPI && create_UPI?.message);
      setAdded(false);
      close();
    } else if (create_UPI && create_UPI?.success === false && added) {
      alert.error(create_UPI && create_UPI?.message);
      setAdded(false);
    }
  }, [create_UPI, close, added, alert]);
  return (
    <>
      <HeadingTitle
        title="Add UPI Details"
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
        center={true}
        bnk={true}
      />

      {/* input container row starts here */}
      <form
        onSubmit={(e) => handleAddUPI(e)}
        className="add-bank-modal-container"
      >
        <div className="login-input">
          <input
            type="text"
            placeholder="Enter Account Holder Name"
            value={upi_id}
            onChange={(e) => setUpi_id(e.target.value)}
          />
        </div>
        <Button type="submit">{loading ? <CustomLoader /> : "SUBMIT"}</Button>
      </form>
      {/* input container row ends here */}
    </>
  );
};

export default AddUPIModal;
