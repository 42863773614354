import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BASE_URL } from "../../BaseURL";
import api from "../../Api";

const ProtectedRoutes = () => {
  const location = useLocation();
  let isAuthenticated = JSON.parse(localStorage.getItem("is_affiliate"));

  useEffect(() => {
    const fetch_Data = () => {
      api
        .post(`${BASE_URL}/get-user-data`)
        .then((response) => {
          console.log("Response: ", response.data.data);
          localStorage.setItem(
            "is_affiliate",
            response.data.data.is_affiliate_user
          );
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    fetch_Data();
  }, []);

  // 👇🏽 could be a check inside a global state, like a context
  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to="/basic-details" state={{ from: location }} />;
};

export default ProtectedRoutes;
