import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import "./WithdrawModal.css";
import Dropdown from "../../Dropdown/Dropdown";
import { Alert, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  get_bank_upi_details_Actions,
  withdraw_amount__Actions,
} from "../../../Actions/Actions";
import CustomLoader from "../../Loader/CustomLoader";
import { useAlert } from "react-alert";

const payment_list_arr = [
  { value: "UPI", label: "UPI" },
  { value: "Bank Account", label: "Bank Account" },
];
const WithdrawModal = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { close } = props;

  // bank/upi list state
  const { bank_data, loading } = useSelector((state) => state.bank_UPI_details);

  // withdrawl amount state
  const {
    amount_withdrawl,
    error,
    loading: withdraw_amount_loading,
  } = useSelector((state) => state.withdrawlAmount);

  const [amount, setAmount] = useState();
  const [payment_method, setPayment_method] = useState("");

  const handlePaymentMethod = (val) => {
    setPayment_method(val?.value);
  };

  const [bank_id, setBank_id] = useState();

  const handleDetails = (val) => {
    setBank_id(val?.id);
    console.log(val);
  };

  const [isHit, setIshit] = useState(false);

  const handlewithdrawAmount = () => {
    setIshit(true);
    dispatch(withdraw_amount__Actions(parseInt(amount), bank_id));
  };

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  useEffect(() => {
    if (amount_withdrawl && amount_withdrawl?.success === true && isHit) {
      setIshit(false);
      setsuccessMsg(amount_withdrawl && amount_withdrawl?.message);
      setTimeout(() => {
        setsuccessMsg("");
      }, 5000);
      // Clear error message if previously set
      setErrMsg("");
    } else if (
      amount_withdrawl &&
      amount_withdrawl?.success === false &&
      isHit
    ) {
      // alert.error(amount_withdrawl && amount_withdrawl?.message);
      setErrMsg(amount_withdrawl && amount_withdrawl?.message);
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
      // Clear success message if previously set
      setsuccessMsg("");
      setIshit(false);
    } else if (isHit && error) {
      setErrMsg(error);
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
      // Clear success message if previously set
      setsuccessMsg("");
      setIshit(false);
      // alert.error(error);
    }
  }, [isHit, alert, amount_withdrawl, error]);

  console.log("Withdrawl Amount: ", amount_withdrawl);

  useEffect(() => {
    dispatch(get_bank_upi_details_Actions());
  }, [dispatch]);

  return (
    <>
      <HeadingTitle
        title="Withdraw Amount"
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
      />
      <div
        id="success"
        style={{ display: successMsg !== "" ? "block" : "none" }}
      >
        {successMsg && <Alert severity="success">{successMsg}</Alert>}
      </div>
      <div id="error" style={{ display: errMsg !== "" ? "block" : "none" }}>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </div>
      <div className="withdrawl-amount-container">
        <div className="input">
          <label htmlFor="product_link">Enter Amount</label> <br />
          <input
            type="number"
            name="Enter_amount"
            id="Enter_amount"
            value={amount}
            placeholder="Enter Amount"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="input">
          <Dropdown
            label="Select Payment Method"
            placeholder="Select Payment Method"
            borderRadius={true}
            dropdown={payment_list_arr}
            onData={handlePaymentMethod}
          />
        </div>
        <div className="input">
          <Dropdown
            label={`${
              payment_method === "UPI" ? "Select UPI" : "Select Bank Account"
            }`}
            placeholder={`${
              payment_method === "UPI" ? "Select UPI ID" : "Select Bank Account"
            }`}
            borderRadius={true}
            dropdown={
              payment_method === "UPI"
                ? bank_data &&
                  Array.isArray(bank_data?.data?.upi_id) &&
                  bank_data?.data?.upi_id
                : payment_method === "Bank Account" &&
                  bank_data &&
                  Array.isArray(bank_data?.data?.bank_account) &&
                  bank_data?.data?.bank_account
            }
            loading={loading}
            onData={handleDetails}
          />
        </div>
        <Button type="submit" onClick={handlewithdrawAmount}>
          {withdraw_amount_loading ? <CustomLoader /> : "Submit"}
        </Button>
      </div>
    </>
  );
};

export default WithdrawModal;
