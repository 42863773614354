import React, { useEffect, useRef, useState } from "react";
import MetaData from "../../Components/MetaData/MetaData";
import SideBar from "../../Components/SideBar/SideBar";
import Navbar from "../../Components/Navbar/Navbar";
import HeaderText from "../../Components/HeaderText/HeaderText";
import "./IdeaHub.css";
import { Button } from "@mui/material";
import ProductList from "../../Components/ProductList/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { get_Idea_hub_product_list__Actions } from "../../Actions/Actions";
import Loader from "../../Components/Loader/Loader";
import { useAlert } from "react-alert";
import api from "../../Api";
import { BASE_URL } from "../../BaseURL";
import AnimatedLoader from "../../Components/Loader/AnimatedLoader";

const arr = ["Best Sellers", "Top Rated"];

// const product_arr = [
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/1.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/2.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/3.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/4.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/5.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/6.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/7.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/8.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
//   {
//     uri: require("../../Assets/Images/IdeaHub_Img/9.png"),
//     overlayTitle: "Total Sales in Last 24 hours 2000 Sold",
//     title: "Men Henley Neck Full Sleeve...",
//     price: "₹399",
//     textOver_Line: "₹1299",
//   },
// ];
const IdeaHub = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const alert = useAlert();
  const dispatch = useDispatch();

  // ideahub_product_list state
  const { idea_hub_product, loading } = useSelector(
    (state) => state.ideaHubProductList
  );

  const [page, setPage] = useState(2);

  console.log("Idea Hub Product List: ", idea_hub_product);

  const [idea_hub_product_list, setIdeaHub_product_list] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const pageRef = useRef(page);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      dispatch(
        get_Idea_hub_product_list__Actions(
          1,
          9,
          activeIndex === 0 ? "best_sellers" : "top_rated"
        )
      );
    }
  }, [dispatch, isActive, activeIndex]);

  const handleChangeModule = (i) => {
    setIsActive(false);
    setIdeaHub_product_list([]);
    setActiveIndex(i);
    setPage(2);
    // dispatch(
    //   get_Idea_hub_product_list__Actions(
    //     1,
    //     9,
    //     i === 0 ? "best_sellers" : "top_rated"
    //   )
    // );
  };

  useEffect(() => {
    if (!isActive) {
      idea_hub_product &&
        setIdeaHub_product_list(
          idea_hub_product &&
            Array.isArray(idea_hub_product?.data?.data) &&
            idea_hub_product?.data?.data
        );
    }
  }, [idea_hub_product, isActive]);

  let item_per_page = 9;

  const handleNext = () => {
    setIsActive(true);
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    setIsLoading(true);
    api
      .post(`${BASE_URL}/idea-hub?page=${page}`, {
        item_per_page,
        tag: activeIndex === 0 ? "best_sellers" : "top_rated",
      })
      .then((response) => {
        console.log("nxx", response?.data?.data);
        const newProducts = response?.data?.data?.data;
        if (newProducts.length === 0) {
          setHasMore(false);
          // setIsActive(false);
        } else {
          // Combine new products with the existing ones
          setIdeaHub_product_list((prevList) => {
            // Check if prevList is an array before using map
            const existingIds = Array.isArray(prevList)
              ? prevList.map((product) => product.id)
              : [];

            const filteredNewProducts = newProducts.filter(
              (product) => !existingIds.includes(product.id)
            );

            return Array.isArray(prevList)
              ? [...prevList, ...filteredNewProducts]
              : filteredNewProducts;
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsActive(false);
        alert.error(err.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <MetaData title={`Idea Hub - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          {loading && !isActive ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderText
                title="Idea Hub"
                description="All content ideas are displayed in the Asia/Calcutta local timezone. Please note that product prices and availability may change. Kindly ensure that all deals are currently active and verify the actual price and discount before sharing"
              />
              {/* header component ends here */}

              {/* filter component starts here */}
              <div className="filter-row-grid">
                {arr &&
                  arr.map((i, index) => (
                    <Button
                      style={{
                        background:
                          activeIndex === index && "rgba(119, 0, 21, 1)",
                      }}
                      onClick={() => handleChangeModule(index)}
                      key={index}
                    >
                      <p style={{ color: activeIndex === index && "white" }}>
                        {i}
                      </p>
                    </Button>
                  ))}
              </div>
              {/* filter component ends here */}

              {/* product List component starts here */}
              <ProductList
                handleNext={handleNext}
                data={idea_hub_product_list && idea_hub_product_list}
                hasMore={hasMore}
              />
              {/* product List component ends here */}

              {/* Loader */}
              <div className="loader-container-product">
                {isLoading ? <AnimatedLoader width={true} /> : null}
              </div>
              {/* Loader */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IdeaHub;
