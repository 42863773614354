import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createBank_details_Actions } from "../../../Actions/Actions";
import CustomLoader from "../../Loader/CustomLoader";
import { useAlert } from "react-alert";

const AddBankModal = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [bank_name, setBank_name] = useState("");
  const [bank_user_name, setBank_user_name] = useState("");
  const [ifsc_code, setIfsc_code] = useState("");
  const [account_number, setAccount_Number] = useState("");

  //   create_bank_state
  const { create_bank, loading } = useSelector((state) => state.createBankData);

  const [added, setAdded] = useState(false);

  const handleAddBank = (e) => {
    e.preventDefault();
    setAdded(true);
    dispatch(
      createBank_details_Actions(
        bank_name,
        bank_user_name,
        ifsc_code,
        account_number
      )
    );
  };

  const { close } = props;

  useEffect(() => {
    if (create_bank && create_bank?.success === true && added) {
      alert.success(create_bank && create_bank?.message);
      close();
      setAdded(false);
    } else if (create_bank && create_bank?.success === false && added) {
      alert.error(create_bank && create_bank?.message);
      setAdded(false);
    }
  }, [create_bank, alert, close, added]);

  console.log("Bank Deatils: ", create_bank);
  return (
    <>
      <HeadingTitle
        title="Add Bank Details"
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
        center={true}
        bnk={true}
      />

      {/* input container row starts here */}
      <form
        onSubmit={(e) => handleAddBank(e)}
        className="add-bank-modal-container"
      >
        <div className="login-input">
          <input
            type="text"
            placeholder="Enter Account Holder Name"
            value={bank_user_name}
            onChange={(e) => setBank_user_name(e.target.value)}
          />
        </div>
        <div className="login-input">
          <input
            type="text"
            placeholder="Enter Account Number"
            value={account_number}
            onChange={(e) => setAccount_Number(e.target.value)}
          />
        </div>
        <div className="login-input">
          <input
            type="text"
            placeholder="Bank Name"
            value={bank_name}
            onChange={(e) => setBank_name(e.target.value)}
          />
        </div>
        <div className="login-input">
          <input
            type="text"
            placeholder="Enter IFSC Code"
            value={ifsc_code}
            onChange={(e) => setIfsc_code(e.target.value)}
          />
        </div>
        <Button type="submit">{loading ? <CustomLoader /> : "SUBMIT"}</Button>
      </form>
      {/* input container row ends here */}
    </>
  );
};

export default AddBankModal;
