import React, { useCallback, useEffect, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import "./LinkModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  create_product_link__Actions,
  create_product_link_url_name__Actions,
  create_product_link_url_name_subgroup_ids__Actions,
} from "../../../Actions/Actions";
import AnimatedLoader from "../../Loader/AnimatedLoader";
import "./GetLinkModal.css";
import { Alert, Tooltip, Zoom } from "@mui/material";

const GetLinkModal = (props) => {
  const dispatch = useDispatch();

  // get_product_link_state
  const { product_link, loading } = useSelector((state) => state.productLink);

  const tags_list = ["HTML", "Link", "Short Link"];
  const { close, type, url, link_name, sub_group_ids, getLink } = props;

  const [checkbox_value, setCheckbox_value] = useState("HTML");

  // const [isRunning, setIsRunning] = useState(true);

  const handleCheckbox = (val) => {
    setCheckbox_value(val);
  };

  console.log("Type: ", type);
  console.log("URL: ", `https://nuxt.hiscraves.com/product/${url}`);
  console.log("LinkName: ", link_name);

  const [isHit, setIsHit] = useState(false);

  const [shorten_url, setShortern_url] = useState("");

  useEffect(() => {
    if (
      sub_group_ids?.length <= 0 ||
      sub_group_ids === undefined ||
      sub_group_ids === null
    ) {
      if (
        link_name !== "" &&
        link_name !== null &&
        link_name !== undefined
        // isRunning
      ) {
        dispatch(create_product_link_url_name__Actions(type, url, link_name));
        setIsHit(true);
        // getLink();
      } else if (
        link_name === "" ||
        link_name === null ||
        link_name === undefined
      ) {
        dispatch(
          create_product_link__Actions(
            type,
            `https://nuxt.hiscraves.com/product/${url}`
          )
        );
      }
    } else if (sub_group_ids?.length > 0) {
      dispatch(
        create_product_link_url_name_subgroup_ids__Actions(
          "collection",
          link_name,
          sub_group_ids
        )
      );
      setIsHit(true);
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const handleLink = useCallback(() => {
    getLink(shorten_url);
  }, [getLink, shorten_url]);

  useEffect(() => {
    if (isHit && product_link?.data?.short_url) {
      setShortern_url(product_link?.data?.short_url);
      setIsHit(false);
    }
    if (
      shorten_url !== "" &&
      shorten_url !== undefined &&
      shorten_url !== null
    ) {
      handleLink();
    }
  }, [isHit, product_link?.data?.short_url, handleLink, shorten_url]);

  console.log("SHORTERN URL: ", shorten_url);

  // useEffect(() => {
  //   if (
  //     isRunning &&
  //     product_link?.data?.short_url !== null &&
  //     product_link?.data?.short_url !== undefined
  //   ) {
  //     getLink(product_link?.data?.short_url);
  //   }
  // }, [getLink, isRunning, product_link?.data?.short_url]);

  console.log("SHORT URL: ", product_link?.data?.short_url);

  console.log("Product Link: ", product_link);
  console.log("Sub Groups Id: ", sub_group_ids);

  // Handle Copy
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  const handleCopy = async (copy) => {
    try {
      await navigator.clipboard.writeText(copy);
      setsuccessMsg("Copied Successfully");
      setTimeout(() => {
        setsuccessMsg("");
      }, 5000);
      // Clear error message if previously set
      setErrMsg("");
    } catch (error) {
      setErrMsg("Something Went Wrong");
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
      // Clear success message if previously set
      setsuccessMsg("");
    }
  };

  console.log(checkbox_value);
  return (
    <>
      <HeadingTitle
        title="Get the Link"
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
        center={true}
      />
      {loading ? (
        <AnimatedLoader top={true} />
      ) : (
        <div className="link-container">
          <p>
            Directions : Highlight and copy the HTML below, then paste it into
            the code for your website.
          </p>
          <div className="checkbox-container-fluid">
            {tags_list &&
              tags_list.map((i, index) => (
                <div className="flex-row-container" key={index}>
                  {/* <label
                    className="container-radio"
                    style={{
                      color: checkbox_value === i && "rgba(119, 0, 21, 1)",
                    }}
                  >
                    {i}
                    <input
                      type="radio"
                      value={checkbox_value}
                      onChange={() => handleCheckbox(i, index)}
                      name="radio"
                      checked={
                        checkbox_value === i && isIndex === index
                          ? true
                          : false
                      }
                    />
                    <span className="checkmark"></span>
                  </label> */}

                  <input
                    value={checkbox_value}
                    onChange={() => handleCheckbox(i, index)}
                    className="radio-pl"
                    type="radio"
                    id={`${index + "featured-1"}`}
                    name="featured"
                    checked={checkbox_value === i ? true : false}
                  />
                  <label for={`${index + "featured-1"}`}>{i}</label>
                </div>
              ))}
          </div>

          <div className="tags-container">
            <Tooltip
              title={"Copy to Clipboard"}
              arrow
              TransitionComponent={Zoom}
            >
              <p
                onClick={() =>
                  handleCopy(
                    checkbox_value === "HTML"
                      ? product_link?.data?.html
                      : checkbox_value === "Link"
                      ? product_link?.data?.long_url
                      : checkbox_value === "Short Link"
                      ? product_link?.data?.short_url
                      : null
                  )
                }
              >
                {checkbox_value === "HTML"
                  ? product_link?.data?.html
                  : checkbox_value === "Link"
                  ? product_link?.data?.long_url
                  : checkbox_value === "Short Link"
                  ? product_link?.data?.short_url
                  : null}
              </p>
            </Tooltip>
          </div>
          <div id="success" style={{ display: successMsg ? "block" : "none" }}>
            {successMsg && <Alert severity="success">{successMsg}</Alert>}
          </div>
          <div id="error" style={{ display: errMsg ? "block" : "none" }}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
          </div>
        </div>
      )}
    </>
  );
};

export default GetLinkModal;
