import React, { useCallback, useEffect, useState } from "react";
import StepperForm from "../../Components/Stepper/StepperForm";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "./BankUpiDetails.css";
import Subscribe from "../../Components/Subscribe/Subscribe";
import { useDispatch, useSelector } from "react-redux";
import { get_bank_upi_details_Actions } from "../../Actions/Actions";
import Loader from "../../Components/Loader/Loader";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";

const BankUpiDetails = () => {
  const dispatch = useDispatch();

  const { is_affiliate } = useParams();

  // get_bank_list_state
  const { bank_data, loading } = useSelector((state) => state.bank_UPI_details);

  const navigate = useNavigate();
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (!finishStatus) {
        if (window.confirm("Do you want to go back? Your Data Will Be Lost")) {
          setfinishStatus(true);
          // your logic
          navigate("/basic-details");
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      }
    },
    [finishStatus, navigate]
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  const [active_set, setActive_set] = useState(1);

  // modal popup code
  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
        />
      );
    }
    return null;
  };
  // modal popup code

  console.log("BANK_ DATA: ", bank_data);

  useEffect(() => {
    if (isIndex === null) {
      dispatch(get_bank_upi_details_Actions());
    }
  }, [dispatch, isIndex]);

  const handleSuccess = () => {
    setActive_set(3);
    handleModal(4);
  };

  const handleDashboard = () => {
    let isOk = localStorage.getItem("is_affiliate");
    if (isOk) {
      navigate("/");
    } else {
      navigate("/basic-details");
    }
  };

  console.log("Params: ", is_affiliate);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* stepper component starts here */}
          <StepperForm active={active_set} />
          {/* stepper component ends here */}

          {/* Upi container */}
          <div className="upi-container-row">
            {/* header container starts here */}
            <div className="upi-header-container">
              <div>
                <h1>UPI</h1>
              </div>
              <div>
                <Button onClick={() => handleModal(3)}>ADD</Button>
              </div>
            </div>
            {/* header container ends here */}

            {/* upi list container starts here */}
            <div className="upi-list-grid-row">
              {bank_data &&
                Array.isArray(bank_data?.data?.upi_id) &&
                bank_data?.data?.upi_id?.map((i) => (
                  <div key={i}>
                    <input type="text" name="" id="" value={i.upi_id} />
                  </div>
                ))}
            </div>
            {/* upi list container ends here */}

            {/* header container starts here */}
            <div className="upi-header-container bank">
              <div>
                <h1>Bank Details</h1>
              </div>
              <div>
                <Button onClick={() => handleModal(2)}>ADD</Button>
              </div>
            </div>
            {/* header container ends here */}

            {/* bank list container starts here */}
            <div className="bank-list-grid-row">
              {bank_data &&
                Array.isArray(bank_data?.data?.bank_account) &&
                bank_data?.data?.bank_account?.map((i, index) => (
                  <div key={index}>
                    <h4>{i.bank_user_name}</h4>
                    <p>Account Number: {i.account_number}</p>
                    <p>IFSC Code: {i.ifsc_code}</p>
                  </div>
                ))}
            </div>
            {/* bank list container ends here */}

            <div className="next-cta">
              {active_set === 3 ? (
                <Button onClick={() => handleDashboard()}>
                  GO TO DASHBOARD
                </Button>
              ) : (
                <Button onClick={() => handleSuccess()}>NEXT</Button>
              )}
            </div>

            <Subscribe />
          </div>
          {/* upi container */}
        </div>
      )}
      {renderModal()}
    </>
  );
};

export default BankUpiDetails;
