import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import { useState } from "react";
import WithdrawModal from "../ModalContent/WithdrawModal/WithdrawModal";
import CloseIcon from "@mui/icons-material/Close";
import GetLinkModal from "../ModalContent/GetLinkModal/GetLinkModal";
import AddBankModal from "../ModalContent/AddBankModal/AddBankModal";
import AddUPIModal from "../ModalContent/AddUPIModal/AddUPIModal";
import SuccessModal from "../ModalContent/SuccessModal/SuccessModal";
import AddProducts from "../ModalContent/AddProducts/AddProducts";

const ModalContainer = (props) => {
  const { index } = props;
  const [width, setWidth] = useState(356);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: 0,
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    background:
      index === 4
        ? "linear-gradient(12deg, #29A658 -10.62%, #158C28 105.27%)"
        : index === 0
        ? "#fff"
        : "",
    backgroundColor: "#F1F3F6",
    borderRadius: 2.75,
    height:
      index === 1
        ? "300px"
        : index === 2
        ? "460px"
        : index === 3
        ? "230px"
        : index === 0
        ? "430px"
        : index === 5
        ? "400px"
        : "412px",
    border: index === 4 ? "" : "1px solid #D6D6D6",
    overflowY: index === 5 || index === 1 || index === 0 ? "auto" : "",
    outline: "none",
  };

  const backdropStyle = {
    // Add backdrop filter for blur
    backdropFilter: "blur(2px)",
  };

  const componentMap = {
    0: <WithdrawModal close={props.closeModal} />,
    1: (
      <GetLinkModal
        close={props.closeModal}
        type={props.type}
        url={props.url}
        link_name={props.link_name}
        getLink={props.getLink}
        sub_group_ids={props.sub_group_ids}
      />
    ),
    2: <AddBankModal close={props.closeModal} />,
    3: <AddUPIModal close={props.closeModal} />,
    4: <SuccessModal close={props.closeModal} />,
    5: (
      <AddProducts
        getIds={props.getIds}
        getArrImages={props.getArrImages}
        categories_id={props.categories_id}
        close={props.closeModal}
      />
    ),
  };

  useEffect(() => {
    if (index === 0) {
      setWidth(394);
    } else if (index === 1) {
      setWidth(930);
    }
  }, [index]);

  return (
    <Modal
      open={props.Isopen}
      onClose={props.closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: backdropStyle,
      }}
      className="modal-container"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{componentMap[index]}</Box>
    </Modal>
  );
};

export default ModalContainer;

export const HeadingTitle = (props) => {
  return (
    <>
      <div
        className="header-cross-title"
        style={{
          borderBottom:
            props.title === "Get the Link" || props.bnk ? "none" : "",
          paddingBottom:
            props.title === "Get the Link" || props.bnk ? "0px" : "",
        }}
      >
        <Typography
          style={{
            color: "#1C1C1C",
            textAlign: props.center ? "center" : "",
          }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={`${props.bnk && "h2-head"}`}
        >
          {props.title}
        </Typography>
        <div className="bg-cross" onClick={props.closeModal}>
          {/* <img src={CrossIcon} alt="" /> */}
          <CloseIcon className="cross-icon-col" />
        </div>
      </div>
      {props.desc && (
        <p
          style={{ marginTop: props.descTop ? "5px" : "" }}
          className="description-text"
        >
          {props.desc}
        </p>
      )}
    </>
  );
};
