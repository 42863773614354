import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import Icon from "../../Assets/Sidebar_Logo/link-square.png";
import Dropdown, { customStylesFilter } from "../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  get_All_Categories__Actions,
  get_Product_Info__Actions,
} from "../../Actions/Actions";
import { useAlert } from "react-alert";
import { DEFAULT_IMG } from "../../BaseURL";
import AnimatedLoader from "../Loader/AnimatedLoader";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import ModalContainer from "../ModalContainer/ModalContainer";
import Select from "react-select";

const arr = ["Single Product", "Create a collections"];
const new_arr = [
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
  {
    uri: DEFAULT_IMG,
  },
];

const SingleProduct = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // get_single_product_img_state
  const {
    product_info,
    loading: single_product_loading,
    error,
  } = useSelector((state) => state.getProductInfo);

  // categories state
  const { categories, loading: categories_loading } = useSelector(
    (state) => state.getAllCategories
  );

  const [checkbox_value, setCheckbox_value] = useState("Single Product");
  const [active, setActive] = useState(false);

  const [product_link, setProduct_link] = useState("");
  const [link_name, setLink_name] = useState("");

  const [sub_group_ids, setsub_group_ids] = useState([]);

  const [categories_id, setCategories_id] = useState([]);

  const handleCheckbox = (val) => {
    setsub_group_ids([]);
    setCategories_id([]);
    setLink_name("");
    setCheckbox_value(val);
    setActive(true);
    setShotern_url("");
  };

  console.log(checkbox_value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setActive(false);
    }, 500); // Adjust the delay based on your animation duration
    return () => clearTimeout(timeoutId);
  }, [checkbox_value]);

  const [isHit, setIsHit] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsHit(true);
      // Perform some action when Enter key is pressed
      // console.log("Enter key pressed!");
      dispatch(get_Product_Info__Actions("product", product_link));
    }
  };

  useEffect(() => {
    if (product_link && product_link?.length > 3) {
      dispatch(get_Product_Info__Actions("product", product_link));
    }
  }, [product_link, dispatch]);

  useEffect(() => {
    if (product_info && product_info?.success === true && isHit) {
      alert.success(product_info && product_info?.message);
      setIsHit(false);
      setProduct_link("");
    } else if (product_info && product_info?.success === false && isHit) {
      alert.error(product_info && product_info?.message);
      setProduct_link("");
      setIsHit(false);
    } else if (error && isHit) {
      alert.error(error);
      setProduct_link("");
    }
  }, [alert, isHit, product_info, error]);

  console.log("Product Info: ", product_info);

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    if (categories_id?.length <= 0 && index === 5) {
      alert.error("Please Select Product Category First...");
      return;
    }
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [shotern_url, setShotern_url] = useState(null);

  const getLink = (i) => {
    console.log("PROPS SHORT URL: ", i);
    setShotern_url(i);
  };

  const [img_arr_data, setImg_arr_data] = useState([]);

  const getArrImages = (val) => {
    console.log("Arr: ", val);
    setImg_arr_data(val);
  };

  const handleGetIds = (val) => {
    setsub_group_ids(val);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          url={product_link}
          type={"product"}
          link_name={link_name}
          getLink={getLink}
          categories_id={categories_id}
          getArrImages={getArrImages}
          getIds={handleGetIds}
          sub_group_ids={sub_group_ids}
        />
      );
    }
    return null;
  };

  const inputRef = useRef(null);

  const handleCopyClick = async () => {
    try {
      // Select the text in the input field
      inputRef.current.select();

      // Copy the selected text to the clipboard
      await navigator.clipboard.writeText(inputRef.current.value);

      alert.success("Text copied to clipboard");
    } catch (err) {
      console.error("Error copying text to clipboard:", err);
    }
  };

  useEffect(() => {
    if (checkbox_value === "Create a collections") {
      dispatch(get_All_Categories__Actions());
      setShotern_url(null);
    }
  }, [checkbox_value, dispatch]);

  console.log("Categories: ", categories?.data);

  const handleData = (val) => {
    const selectedValues = val.map((option) => option?.value);
    setCategories_id(selectedValues);
  };

  const transformedOptions =
    categories &&
    Array.isArray(categories?.data) &&
    categories?.data?.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));

  console.log("Categories: ", checkbox_value, shotern_url);

  return (
    <>
      {/* product select option */}
      <div className="product-selection-container">
        <div
          className={`product-selecton-grid-row ${
            active ? "fade-in active" : ""
          }`}
        >
          <div className="product-selection-grid-col">
            <p>Select Option</p>
            {/* checkbox component starts here */}
            <div className="checkbox-container-fluid">
              {arr &&
                arr.map((i, index) => (
                  <div key={index}>
                    <label
                      class="container-radio"
                      style={{
                        color: checkbox_value === i && "rgba(119, 0, 21, 1)",
                      }}
                    >
                      {i}
                      <input
                        type="radio"
                        value={checkbox_value}
                        onChange={() => handleCheckbox(i)}
                        name="radio"
                        checked={checkbox_value === i ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
            {/* checkbox component ends here */}

            {checkbox_value === "Create a collections" ? (
              <div className="input-container">
                <label className="label-input" htmlFor="">
                  Select a Product Category
                </label>
                <Select
                  value={
                    transformedOptions &&
                    transformedOptions.filter((option) =>
                      categories_id.includes(option?.value)
                    )
                  }
                  defaultValue={categories_id}
                  onChange={handleData}
                  isMulti
                  styles={customStylesFilter}
                  isLoading={categories_loading}
                  placeholder="Please choose a product category"
                  options={transformedOptions && transformedOptions}
                />
                <div onClick={() => handleModal(5)}>
                  <Dropdown
                    label={"Search and Add Products"}
                    placeholder="Select Product"
                    bgColor={true}
                  />
                </div>
                <div className="input">
                  <label htmlFor="product_link">
                    Name Your Collection Link
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    name="Enter_name"
                    id="Enter_name"
                    value={link_name}
                    placeholder="Enter name"
                    onChange={(e) => setLink_name(e.target.value)}
                  />
                </div>
                <Button
                  disabled={
                    categories_id?.length <= 0 || !link_name ? true : false
                  }
                  type="submit"
                  onClick={() => handleModal(1)}
                >
                  Create a Link
                </Button>
              </div>
            ) : (
              <div
                // onSubmit={(e) => handleSingleProduct(e)}
                className="input-container"
              >
                {/* input field starts here */}
                <div className="input">
                  <label htmlFor="product_link">Enter Product Link</label>{" "}
                  <br />
                  <input
                    type="text"
                    name="product_link"
                    id="product_link"
                    value={product_link}
                    placeholder="Paste Product URL here"
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setProduct_link(e.target.value)}
                  />
                </div>
                {/* input field ends here */}

                {/* input field starts here */}
                <div className="input">
                  <label htmlFor="linkName">Name Your Link</label> <br />
                  <input
                    type="text"
                    name="linkName"
                    id="linkName"
                    placeholder="Enter name"
                    value={link_name}
                    onChange={(e) => setLink_name(e.target.value)}
                  />
                </div>
                {/* input field ends here */}

                <Button
                  type="submit"
                  disabled={!product_link || !link_name ? true : false}
                  onClick={() => handleModal(1)}
                >
                  Create a Link
                </Button>
              </div>
            )}
          </div>
          <div>
            {checkbox_value === "Create a collections" ? (
              <div>
                {/* multiple images grid container start here */}
                {img_arr_data?.length <= 0 ? (
                  <div className="multiple-images-grid-row">
                    {new_arr &&
                      new_arr.slice(0, 4).map((i, index) => (
                        <div
                          className={index === 3 && "blur-img-container"}
                          key={index}
                        >
                          {index === 3 && (
                            <div className="img-overlay-blur">
                              <p>{new_arr.length}+</p>
                            </div>
                          )}
                          <img
                            className={index === 3 && "new-blur"}
                            src={i.uri}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="multiple-images-grid-row">
                    {img_arr_data &&
                      img_arr_data.slice(0, 4).map((i, index) => (
                        <div
                          className={index === 3 && "blur-img-container"}
                          key={index}
                        >
                          {index === 3 && (
                            <div className="img-overlay-blur">
                              <p>{img_arr_data.length}+</p>
                            </div>
                          )}
                          <img
                            className={index === 3 && "new-blur"}
                            src={i}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                )}
                {/* multiple images grid container ends here */}
                <div className="input-fluid">
                  <input
                    ref={inputRef}
                    placeholder="http://"
                    value={shotern_url}
                    type="text"
                  />
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={"Copy To Clipboard"}
                  >
                    <Button onClick={handleCopyClick} className="link-img">
                      <img src={Icon} alt="" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="single-product">
                {single_product_loading ? (
                  <AnimatedLoader />
                ) : (
                  <img
                    src={
                      product_info &&
                      product_info?.data?.front_img_url !== "" &&
                      product_info?.data?.front_img_url !== null &&
                      product_info?.data?.front_img_url !== undefined
                        ? product_info?.data?.front_img_url
                        : DEFAULT_IMG
                    }
                    className="default_icon"
                    alt=""
                  />
                )}
                <div
                  className="input-fluid"
                  style={{ marginTop: single_product_loading ? "30px" : "" }}
                >
                  <input
                    ref={inputRef}
                    placeholder="http://"
                    value={shotern_url}
                    type="text"
                  />
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={"Copy To Clipboard"}
                  >
                    <Button onClick={handleCopyClick} className="link-img">
                      <img src={Icon} alt="" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* product select option */}
      {renderModal()}
    </>
  );
};

export default SingleProduct;
