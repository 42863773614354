import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Screens/Dashboard/Dashboard";
import ProductLinks from "./Screens/ProductLinks/ProductLinks";
import IdeaHub from "./Screens/IdeaHub/IdeaHub";
import Commission from "./Screens/Commission/Commission";
import Transactions from "./Screens/Transactions/Transactions";
import Reports from "./Screens/Reports/Reports";
import Help from "./Screens/Help/Help";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import LoginWithInformation from "./Screens/LoginWithInformation/LoginWithInformation";
import BankUpiDetails from "./Screens/BankUpiDetails/BankUpiDetails";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/basic-details/:token?"
          element={<LoginWithInformation />}
        />
        <Route
          path="/bank-details/:is_affiliate"
          element={<BankUpiDetails />}
        />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/product-links" element={<ProductLinks />} />
          <Route path="/idea-hub" element={<IdeaHub />} />
          <Route path="/Commission" element={<Commission />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/help" element={<Help />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
