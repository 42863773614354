import React, { useEffect, useState } from "react";
import StepperForm from "../../Components/Stepper/StepperForm";
import "./LoginWithInformation.css";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Subscribe from "../../Components/Subscribe/Subscribe";
import Footer from "../../Components/Footer/Footer";
import api from "../../Api";
import { BASE_URL } from "../../BaseURL";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  Create_affiliate_user_Actions,
  get_All_state_list_Actions,
} from "../../Actions/Actions";
import Dropdown from "../../Components/Dropdown/Dropdown";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { useAlert } from "react-alert";

const LoginWithInformation = () => {
  const { token } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  // create_affiliate_user state
  const {
    affiliate_user,
    loading: create_user_loading,
    error,
  } = useSelector((state) => state.createaffiliateuser);

  // get_all_state_list state
  const { all_state_list, loading: state_loading } = useSelector(
    (state) => state.stateList
  );

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [user_info, setUser_info] = useState();
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [address_line_one, setAddress_line_one] = useState("");
  const [address_line_two, setAddress_line_two] = useState("");
  const [address_line_three, setAddress_line_three] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [Pincode, setPincode] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [email_id, setEmail_id] = useState("");

  // let token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiNGY3YTljNjc5NzhkN2QyNDcwNzQyZmQyNjMyNmI5ZjQ0OTY4YjFmMWNiMjQyMGU0MTdmNmQ5NWUyMzQyYjM5NzVkOTdhNjk0NTE0NzIwZjciLCJpYXQiOjE3MDI4NzE3ODEuNjk2ODM0LCJuYmYiOjE3MDI4NzE3ODEuNjk2ODM5LCJleHAiOjE3MzQ0OTQxODEuNjg3NDk1LCJzdWIiOiIyMzE0Iiwic2NvcGVzIjpbXX0.TTm1mBw4BsVMiIb3xQMZv1kzWoOj2h6hbHOn5B6kRPmMYGSNQdNl6DniyunaMFy4VNu8grIWVEPwREHQBHoRlR5k84uyL0g_y8wseyxN3MKoSa6nJZ_tTcLpNqYCfp5FvCo5qpyL1nMDnltXoC6iMLu-_VUKwgxKYY4jbvhbTfxZsCw8BmMHiQUSmn0TFvZp7UHXfq2UhXzGOzKL6QmI-WL-ZXB9mtUeJD57YSInzjEY6LDELZYd0D0KAmxt7GmFy9m9KqXFaMv5uQlB9rF21ikYdgDRdYHNGWayrRWLjIEndddG7vlyxXDPV2abUcum-EpITewTG5-naqfktbuL3i0kqVxN4QVU_6JZtNxOCoTJtleMwqLKoYctOlUCZh70m1lc6JckIw_E0k4oomnr7udrl4lDoXwgyTZIiQ7bxXPyUgPLwqQVTtCw-lilZbFA3YpGWMdmRQNeCei_BpHArM1wRwwsPuNSfefoKyAJ-pjzIQ2gM1emog-3qnVm82q8bdExzdt4F6zxETotN2QYazBnVU2VKlAHtqrKHGY-sdzikxiyxDr8AeBhq2FYmQDuhlhqlxO8Q0CKxNM1zMnG4IOjU-SJP2_sx4x1pYXfnpFdVMZyfBa5ZcceD8Mc-okyJNRXy2_JhtAhZJcuwZDRgiSDB4P4npZy3syCEHYxy60";

  const handleState = (val) => {
    setState(val?.value);
  };

  const [isCreated, setIsCreated] = useState(false);

  const handleNext = () => {
    setIsCreated(true);
    dispatch(
      Create_affiliate_user_Actions(
        first_name,
        last_name,
        address_line_one,
        address_line_two,
        city,
        state,
        Pincode,
        mobile_no,
        email_id
      )
    );
    // navigate(`/bank-details`);
  };

  useEffect(() => {
    const fetch_Data = () => {
      api
        .post(`${BASE_URL}/get-user-data`)
        .then((response) => {
          console.log("Response: ", response);
          if (response && response?.data?.success === true) {
            setUser_info(response && response?.data?.data?.customer_data);
            console.log("Affiliate User:", response?.data?.data);
            // let is_affiliate = localStorage.setItem(
            //   "is_affiliate",
            //   response?.data?.data?.is_affiliate_user
            // );
            localStorage.setItem(
              "affiliate_user",
              response?.data?.data?.customer_data?.full_name
            );
            if (response?.data?.data?.is_affiliate_user === true) {
              navigate("/");
            } else {
              navigate("/basic-details");
            }
            setLoading(false);
          } else if (response && response?.data?.success === false) {
            alert.error(response && response?.data?.message);
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert.error(error.message);
          setLoading(false);
        });
    };
    fetch_Data();
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    dispatch(get_All_state_list_Actions());
  }, [dispatch]);

  useEffect(() => {
    if (token !== undefined) {
      localStorage.setItem("token_user", token);
    }
  }, [token]);

  useEffect(() => {
    if (user_info && user_info !== null && user_info !== undefined) {
      setfirst_name(user_info.first_name);
      setlast_name(user_info.last_name);
      setAddress_line_one(user_info.billing_address1);
      setAddress_line_two(user_info.billing_address2);
      setCity(user_info.city);
      setPincode(user_info.billing_pincode);
      setMobile_no(user_info.phone);
      setEmail_id(user_info.email);
    }
  }, [user_info]);

  // useEffect(() => {

  // }, [dispatch]);

  console.log("User: ", user_info);
  console.log("State List: ", all_state_list);

  let checked = "redirect=true";

  useEffect(() => {
    if (affiliate_user && affiliate_user?.success === true && isCreated) {
      navigate(`/bank-details/${checked}`);
      setIsCreated(false);
      localStorage.setItem("is_affiliate", true);
    } else if (
      affiliate_user &&
      affiliate_user?.success === false &&
      isCreated
    ) {
      alert.error(affiliate_user && affiliate_user?.message);
      setIsCreated(false);
    }
    if (error && isCreated) {
      alert.error(error);
    }
  }, [affiliate_user, navigate, isCreated, alert, checked, error]);

  console.log(affiliate_user && affiliate_user);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("is_affiliate")) === true) {
      navigate("/");
    }
  }, [navigate, alert]);

  console.log("Affiliate User: ", affiliate_user);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* stepper component starts here */}
          <StepperForm active={0} />
          {/* stepper component ends here */}

          {/* form component starts here */}
          <div className="form-header-container">
            <h1>Basic Details</h1>
            <p>
              Please read <span>these instructions</span> before you fill this
              form.
            </p>

            <form className="form-login-container">
              <div className="login-input">
                <input
                  type="text"
                  placeholder="First name"
                  value={first_name}
                  onChange={(e) => setfirst_name(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Last name"
                  value={last_name}
                  onChange={(e) => setlast_name(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Address Line 1"
                  value={address_line_one}
                  onChange={(e) => setAddress_line_one(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Address Line 2"
                  value={address_line_two}
                  onChange={(e) => setAddress_line_two(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Address Line 3"
                  value={address_line_three}
                  onChange={(e) => setAddress_line_three(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="login-input">
                {/* <input
                  type="text"
                  placeholder="State, Province or Region"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                /> */}
                <Dropdown
                  placeholder="Choose State"
                  boxShadow={true}
                  dropdown={all_state_list && all_state_list?.data}
                  loading={state_loading}
                  onData={handleState}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Postal Code"
                  value={Pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={mobile_no}
                  disabled={true}
                  onChange={(e) => setMobile_no(e.target.value)}
                />
              </div>
              <div className="login-input">
                <input
                  type="text"
                  placeholder="Email ID"
                  value={email_id}
                  onChange={(e) => setEmail_id(e.target.value)}
                />
              </div>
              <Button onClick={handleNext}>
                {create_user_loading ? <CustomLoader /> : "next"}
              </Button>
            </form>
          </div>
          {/* form component ends here */}

          <div className="upi-container-row">
            <Subscribe />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default LoginWithInformation;
