export const CREATE_AFFILIATE_USER_REQUEST = "CREATE_AFFILIATE_USER_REQUEST"
export const CREATE_AFFILIATE_USER_SUCCESS = "CREATE_AFFILIATE_USER_SUCCESS"
export const CREATE_AFFILIATE_USER_FAILURE = "CREATE_AFFILIATE_USER_FAILURE"


export const GET_ALL_STATE_LIST_REQUEST = "GET_ALL_STATE_LIST_REQUEST"
export const GET_ALL_STATE_LIST_SUCCESS = "GET_ALL_STATE_LIST_SUCCESS"
export const GET_ALL_STATE_LIST_FAILURE = "GET_ALL_STATE_LIST_FAILURE"


export const GET_BANK_UPI_DETAILS_REQUEST = "GET_BANK_UPI_DETAILS_REQUEST"
export const GET_BANK_UPI_DETAILS_SUCCESS = "GET_BANK_UPI_DETAILS_SUCCESS"
export const GET_BANK_UPI_DETAILS_FAILURE = "GET_BANK_UPI_DETAILS_FAILURE"

export const CREATE_BANK_DETAILS_REQUEST = "CREATE_BANK_DETAILS_REQUEST"
export const CREATE_BANK_DETAILS_SUCCESS = "CREATE_BANK_DETAILS_SUCCESS"
export const CREATE_BANK_DETAILS_FAILURE = "CREATE_BANK_DETAILS_FAILURE"

export const CREATE_UPI_DETAILS_REQUEST = "CREATE_UPI_DETAILS_REQUEST"
export const CREATE_UPI_DETAILS_SUCCESS = "CREATE_UPI_DETAILS_SUCCESS"
export const CREATE_UPI_DETAILS_FAILURE = "CREATE_UPI_DETAILS_FAILURE"

export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE"

export const GET_IDEA_HUB_PRODUCT_LIST_REQUEST = "GET_IDEA_HUB_PRODUCT_LIST_REQUEST"
export const GET_IDEA_HUB_PRODUCT_LIST_SUCCESS = "GET_IDEA_HUB_PRODUCT_LIST_SUCCESS"
export const GET_IDEA_HUB_PRODUCT_LIST_FAILURE = "GET_IDEA_HUB_PRODUCT_LIST_FAILURE"

export const GET_COMMISSION_PRODUCT_LIST_REQUEST = "GET_COMMISSION_PRODUCT_LIST_REQUEST"
export const GET_COMMISSION_PRODUCT_LIST_SUCCESS = "GET_COMMISSION_PRODUCT_LIST_SUCCESS"
export const GET_COMMISSION_PRODUCT_LIST_FAILURE = "GET_COMMISSION_PRODUCT_LIST_FAILURE"

export const GET_TRANSACTION_LIST_REQUEST = "GET_TRANSACTION_LIST_REQUEST"
export const GET_TRANSACTION_LIST_SUCCESS = "GET_TRANSACTION_LIST_SUCCESS"
export const GET_TRANSACTION_LIST_FAILURE = "GET_TRANSACTION_LIST_FAILURE"

export const GET_REPORTS_LIST_REQUEST = "GET_REPORTS_LIST_REQUEST"
export const GET_REPORTS_LIST_SUCCESS = "GET_REPORTS_LIST_SUCCESS"
export const GET_REPORTS_LIST_FAILURE = "GET_REPORTS_LIST_FAILURE"

export const CREATE_PRODUCT_LINK_REQUEST = "CREATE_PRODUCT_LINK_REQUEST"
export const CREATE_PRODUCT_LINK_SUCCESS = "CREATE_PRODUCT_LINK_SUCCESS"
export const CREATE_PRODUCT_LINK_FAILURE = "CREATE_PRODUCT_LINK_FAILURE"

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE"

export const WITHDRAWL_AMOUNT_REQUEST = "WITHDRAWL_AMOUNT_REQUEST"
export const WITHDRAWL_AMOUNT_SUCCESS = "WITHDRAWL_AMOUNT_SUCCESS"
export const WITHDRAWL_AMOUNT_FAILURE = "WITHDRAWL_AMOUNT_FAILURE"

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE"

export const GET_PRODUCTS_LIST_REQUEST = "GET_PRODUCTS_LIST_REQUEST"
export const GET_PRODUCTS_LIST_SUCCESS = "GET_PRODUCTS_LIST_SUCCESS"
export const GET_PRODUCTS_LIST_FAILURE = "GET_PRODUCTS_LIST_FAILURE"

export const CLEAR_ERRORS = 'CLEAR_ERRORS';


