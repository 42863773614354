import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Navbar from "../../Components/Navbar/Navbar";
import HeaderText from "../../Components/HeaderText/HeaderText";
import MetaData from "../../Components/MetaData/MetaData";
import "./Transactions.css";
import DataTable from "../../Components/DataTable/DataTable";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { get_Transaction_list__Actions } from "../../Actions/Actions";
import Loader from "../../Components/Loader/Loader";
import { Pagination } from "../../Components/Pagination/Pagination";
import api from "../../Api";
import { BASE_URL } from "../../BaseURL";

const header = [
  "Sr.No.",
  "Transactions ID",
  <>
    Transactions <br />
    Date & Time
  </>,
  <>
    Transactions <br />
    Status
  </>,
  <>
    Transactions <br />
    Amount
  </>,
];

// const data_arr = [
//   {
//     sr_no: "01",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 16, 2023 | 10:15 AM",
//     transaction_status: "Withdraw to Bank",
//     transaction_amount: 1500,
//   },
//   {
//     sr_no: "02",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 15, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 2000,
//   },
//   {
//     sr_no: "03",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 14, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 2000,
//   },
//   {
//     sr_no: "04",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 13, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 2000,
//   },
//   {
//     sr_no: "05",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 12, 2023 | 10:15 AM",
//     transaction_status: "Withdraw to UPI",
//     transaction_amount: 1500,
//   },
//   {
//     sr_no: "06",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 11, 2023 | 10:15 AM",
//     transaction_status: "Withdraw to Bank",
//     transaction_amount: 1500,
//   },
//   {
//     sr_no: "07",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 10, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 2000,
//   },
//   {
//     sr_no: "08",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 09, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 2000,
//   },
//   {
//     sr_no: "09",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 08, 2023 | 10:15 AM",
//     transaction_status: "Income",
//     transaction_amount: 1500,
//   },
//   {
//     sr_no: "10",
//     transaction_id: "TD1234567JAZ",
//     transaction_dt: "Oct 07, 2023 | 10:15 AM",
//     transaction_status: "Withdraw to UPI",
//     transaction_amount: 1500,
//   },
// ];

const Transactions = () => {
  const dispatch = useDispatch();

  // transaction get api state
  const { transaction_list, loading } = useSelector(
    (state) => state.transactionList
  );

  const transaction_metrics_arr = [
    {
      count: "₹" + transaction_list?.data?.total_earning,
      title: "Total Earning",
      bgColor: "rgba(212, 226, 212, 1)",
    },
    {
      count: "₹" + transaction_list?.data?.available_amount,
      title: "Available Amount",
      bgColor: "rgba(219, 196, 240, 1)",
    },
    {
      count: "₹" + transaction_list?.data?.withdraw_amount,
      title: "Withdraw Amount",
      bgColor: "rgba(251, 226, 179, 1)",
    },
  ];

  const [transaction_list_arr, setTransaction_list] = useState([]);
  const [page, setPage] = useState(1);
  const [Pagination_loading, setPagination_loading] = useState(false);

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
        />
      );
    }
    return null;
  };

  const handleData = () => {
    handleModal(0);
  };

  useEffect(() => {
    if (isIndex === null) {
      dispatch(get_Transaction_list__Actions(1));
    }
  }, [dispatch,isIndex]);

  useEffect(() => {
    Array.isArray(transaction_list?.data?.affiliate_transaction?.data) &&
      setTransaction_list(transaction_list?.data?.affiliate_transaction?.data);
  }, [transaction_list?.data?.affiliate_transaction?.data]);

  const fetchNextTransaction = async (page) => {
    setPagination_loading(true);
    try {
      const response = await api.post(`${BASE_URL}/transactions?page=${page}`);
      if (response && response?.data?.success === true) {
        setTransaction_list(response?.data?.data?.affiliate_transaction?.data);
        setPagination_loading(false);
      }
    } catch (error) {
      alert.error(error.message);
      setPagination_loading(false);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNextTransaction(i);
  };

  return (
    <>
      <MetaData title={`Transactions - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderText
                btnTitle="Withdraw Amount"
                btnTrue={true}
                onData={handleData}
                title="Transactions"
                description="Monitor your earnings, account balance, and withdrawal details conveniently through your account dashboard. Stay updated on the funds you've accumulated and view the comprehensive history of your withdrawals at your fingertips."
              />
              {/* header component ends here */}

              {/* transaction_metrics_component starts here */}
              <div className="transaction-metrics-grid-row">
                {transaction_metrics_arr &&
                  transaction_metrics_arr.map((i, index) => (
                    <div key={index} style={{ backgroundColor: i.bgColor }}>
                      <p>{i.count}</p>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>
              {/* transaction_metrics_component ends here */}

              {/* Table component starts here */}
              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable header={header} data_arr={transaction_list_arr} />
                </div>
              </div>
              {/* Table component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={Pagination_loading}
                  totalPage={
                    transaction_list?.data?.affiliate_transaction?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default Transactions;
