import React, { useEffect, useState } from "react";
import HeaderText from "../../Components/HeaderText/HeaderText";
import Navbar from "../../Components/Navbar/Navbar";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/MetaData/MetaData";
import DataTable from "../../Components/DataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { get_Reports_list__Actions } from "../../Actions/Actions";
import Loader from "../../Components/Loader/Loader";
import { Pagination } from "../../Components/Pagination/Pagination";
import api from "../../Api";
import { BASE_URL } from "../../BaseURL";

const header = [
  "Sr.No.",
  "Transactions ID",
  "Picture / Product",
  "Category",
  "Amount",
  "Qty",
  "Commission",
  <>
    Order <br /> Status
  </>,
  <>
    Earn <br />
    Amount
  </>,
];

// const data_reports_arr = [
//   {
//     srNo: "01",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Delivered",
//     earn_amt: "12.5",
//   },
//   {
//     srNo: "02",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "2%",
//     order_status: "Order",
//     earn_amt: "0.0",
//   },
//   {
//     srNo: "03",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Delivered",
//     earn_amt: "12.5",
//   },
//   {
//     srNo: "04",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "2%",
//     order_status: "Order",
//     earn_amt: "0.0",
//   },
//   {
//     srNo: "05",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Delivered",
//     earn_amt: "12.5",
//   },
//   {
//     srNo: "06",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "2%",
//     order_status: "Shipped",
//     earn_amt: "0.0",
//   },
//   {
//     srNo: "07",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "2%",
//     order_status: "Cancel",
//     earn_amt: "0.0",
//   },
//   {
//     srNo: "08",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Shipped",
//     earn_amt: "0.0",
//   },
//   {
//     srNo: "09",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Delivered",
//     earn_amt: "12.5",
//   },
//   {
//     srNo: "10",
//     transaction_id: "TD12345JKS",
//     prod_pic: require("../../Assets/Images/reports_icon.png"),
//     prod_pic_title: "Men Black Full Sleeves T-Shirt",
//     category: "Party Wear",
//     amount: 250,
//     qty: 1,
//     commission: "5%",
//     order_status: "Delivered",
//     earn_amt: "12.5",
//   },
// ];

const Reports = () => {
  const dispatch = useDispatch();

  // report list state
  const { reports_list, loading } = useSelector((state) => state.reportsList);

  useEffect(() => {
    dispatch(get_Reports_list__Actions(1));
  }, [dispatch]);

  const [reports_lists, setReports_lits] = useState([]);

  useEffect(() => {
    Array.isArray(reports_list?.data?.data) &&
      setReports_lits(reports_list && reports_list?.data?.data);
  }, [reports_list]);

  const [page, setPage] = useState(1);
  const [Pagination_loading, setPagination_loading] = useState(false);

  const fetchNextReports = async (page) => {
    setPagination_loading(true);
    try {
      const response = await api.post(`${BASE_URL}/reports?page=${page}`);
      if (response && response?.data?.success === true) {
        setReports_lits(response?.data?.data?.data);
        setPagination_loading(false);
      }
    } catch (error) {
      alert.error(error.message);
      setPagination_loading(false);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNextReports(i);
  };

  return (
    <>
      <MetaData title={`Reports - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderText
                title="Reports"
                description="In the report section, access a comprehensive summary displaying your earnings, commissions, product-specific growth, demand trends, and more. Gain insights into your performance and explore valuable details to optimize your strategies."
              />
              {/* header component ends here */}

              {/* Table component starts here */}
              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable header={header} data_reports_arr={reports_lists} />
                </div>
              </div>
              {/* Table component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={Pagination_loading}
                  totalPage={reports_list && reports_list?.data?.last_page}
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;
