import React from "react";
import MetaData from "../../Components/MetaData/MetaData";
import SideBar from "../../Components/SideBar/SideBar";
import Navbar from "../../Components/Navbar/Navbar";
import HeaderText from "../../Components/HeaderText/HeaderText";
import "./ProductLinks.css";
import SingleProduct from "../../Components/SingleProduct/SingleProduct";

const ProductLinks = () => {
  return (
    <>
      <MetaData title={`Product Links - Hiscraves Affiliate`} />
      <div className="container-grid-row">
        <div>
          <SideBar />
        </div>
        <div>
          {/* Navbar component starts here */}
          <Navbar />
          {/* Navbar component ends here */}

          <div className="container-wrapper">
            {/* header component starts here */}
            <HeaderText
              title="Product Links"
              description="We offer a wide selection of products, allowing you to either pick items one by one or curate a collection for easy use on your website, blogs, and more, jumpstarting your earning journey"
            />
            {/* header component ends here */}

            <SingleProduct />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductLinks;
