import React from "react";
import "./HeaderText.css";
import { Button } from "@mui/material";

const HeaderText = (props) => {
  const { title, description, btnTitle, btnTrue, onData } = props;
  const handleClick = () => {
    onData();
  };
  return (
    <div className="headline-container">
      <div className="header-area">
        <h1>{title}</h1>
        <div>
          {btnTrue && <Button onClick={handleClick}>{btnTitle}</Button>}
        </div>
      </div>
      <p>{description}</p>
    </div>
  );
};

export default HeaderText;
