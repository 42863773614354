import React from "react";
import "./subscribe.css";
import TopRightIcon from "../../Assets/Images/Ellipse 27.png";
import BottomLeftIcon from "../../Assets/Images/Ellipse 26.png";
import { Button } from "@mui/material";

const Subscribe = () => {
  return (
    <>
      <div className="container-subscribe-row">
        <img src={TopRightIcon} alt="" />
        <img src={BottomLeftIcon} alt="" />
        <div className="subscribe-row">
          <h1>SUBSRIBE</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry....
          </p>
          <div className="subscribe-input-cta">
            <input type="text" placeholder="Enter your email" />
            <Button>Subsribe</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
