import React from "react";
import "./Navbar.css";
import { Button } from "@mui/material";
import { useAlert } from "react-alert";

const Navbar = () => {
  const alert = useAlert();

  let customer = localStorage.getItem("affiliate_user");

  const logout = async () => {
    localStorage.removeItem("is_affiliate");
    localStorage.removeItem("token_user");
    localStorage.removeItem("affiliate_user");
    setTimeout(() => {
      window.location.href = "https://nuxt.hiscraves.com";
      alert.success("Logout Successfully...");
    }, 500);
  };

  console.log("CUSTOMER: ", customer);
  return (
    <div className="header-wrapper">
      {/* grid 2 col starts here */}
      <div className="header-grid-row">
        <div>
          <div className="search-area"></div>
        </div>
        <div>
          <div className="avatar-flex-row">
            <div>{customer && customer.slice(0, 1)}</div>
            <div>
              <p>{customer || "No User"}</p>
              <Button onClick={logout}>Logout</Button>
            </div>
          </div>
        </div>
      </div>
      {/* grid 2 col ends here */}
    </div>
  );
};

export default Navbar;
