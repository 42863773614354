import React from "react";

const AnimatedLoader = ({ top, width }) => {
  return (
    // <div style={{ marginTop: top ? "60px" : "0px" }}>
    <div
      className="loading-google-spinner"
      style={{ marginTop: top ? "60px" : "0px" }}
    >
      <svg
        style={{ width: width && "35px" }}
        className="loading-google-spinner__circle-svg"
        viewBox="25 25 50 50"
      >
        <circle
          class="loading-google-spinner__circle-stroke"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
    // </div>
  );
};

export default AnimatedLoader;
