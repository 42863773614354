import {
  CLEAR_ERRORS,
  CREATE_AFFILIATE_USER_FAILURE,
  CREATE_AFFILIATE_USER_REQUEST,
  CREATE_AFFILIATE_USER_SUCCESS,
  CREATE_BANK_DETAILS_FAILURE,
  CREATE_BANK_DETAILS_REQUEST,
  CREATE_BANK_DETAILS_SUCCESS,
  CREATE_PRODUCT_LINK_FAILURE,
  CREATE_PRODUCT_LINK_REQUEST,
  CREATE_PRODUCT_LINK_SUCCESS,
  CREATE_UPI_DETAILS_FAILURE,
  CREATE_UPI_DETAILS_REQUEST,
  CREATE_UPI_DETAILS_SUCCESS,
  GET_ALL_STATE_LIST_FAILURE,
  GET_ALL_STATE_LIST_REQUEST,
  GET_ALL_STATE_LIST_SUCCESS,
  GET_BANK_UPI_DETAILS_FAILURE,
  GET_BANK_UPI_DETAILS_REQUEST,
  GET_BANK_UPI_DETAILS_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_COMMISSION_PRODUCT_LIST_FAILURE,
  GET_COMMISSION_PRODUCT_LIST_REQUEST,
  GET_COMMISSION_PRODUCT_LIST_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_IDEA_HUB_PRODUCT_LIST_FAILURE,
  GET_IDEA_HUB_PRODUCT_LIST_REQUEST,
  GET_IDEA_HUB_PRODUCT_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_REPORTS_LIST_FAILURE,
  GET_REPORTS_LIST_REQUEST,
  GET_REPORTS_LIST_SUCCESS,
  GET_TRANSACTION_LIST_FAILURE,
  GET_TRANSACTION_LIST_REQUEST,
  GET_TRANSACTION_LIST_SUCCESS,
  WITHDRAWL_AMOUNT_FAILURE,
  WITHDRAWL_AMOUNT_REQUEST,
  WITHDRAWL_AMOUNT_SUCCESS,
} from "../Constants/Constants";

// Create_affiliate_user_reducers
export const Create_affiliate_user_Reducers = (
  state = { affiliate_user: {} },
  action
) => {
  switch (action.type) {
    case CREATE_AFFILIATE_USER_REQUEST:
      return {
        loading: true,
      };
    case CREATE_AFFILIATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliate_user: action.payload,
        message: action.payload,
      };
    case CREATE_AFFILIATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_State_List_reducers
export const get_State_List_Reducers = (
  state = { all_state_list: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_STATE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        all_state_list: action.payload,
        message: action.payload,
      };
    case GET_ALL_STATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Bank_UPI_details_reducers
export const get_bank_upi_details_Reducers = (
  state = { bank_data: {} },
  action
) => {
  switch (action.type) {
    case GET_BANK_UPI_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_BANK_UPI_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        bank_data: action.payload,
        message: action.payload,
      };
    case GET_BANK_UPI_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create_Bank_details_reducers
export const createBank_details_Reducers = (
  state = { create_bank: {} },
  action
) => {
  switch (action.type) {
    case CREATE_BANK_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case CREATE_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        create_bank: action.payload,
        message: action.payload,
      };
    case CREATE_BANK_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create_UPI_details_reducers
export const createUPI_details_Reducers = (
  state = { create_UPI: {} },
  action
) => {
  switch (action.type) {
    case CREATE_UPI_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case CREATE_UPI_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        create_UPI: action.payload,
        message: action.payload,
      };
    case CREATE_UPI_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Dashboard_data_reducers
export const get_dashboard_data_Reducers = (
  state = { dashboard_metrics: {} },
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard_metrics: action.payload,
        message: action.payload,
      };
    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Ideahub_product_list_reducers
export const get_Idea_hub_product_list_Reducers = (
  state = { idea_hub_product: {} },
  action
) => {
  switch (action.type) {
    case GET_IDEA_HUB_PRODUCT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_IDEA_HUB_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        idea_hub_product: action.payload,
        message: action.payload,
      };
    case GET_IDEA_HUB_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Commision_product_list_reducers
export const get_commision_product_list_Reducers = (
  state = { commission_product: {} },
  action
) => {
  switch (action.type) {
    case GET_COMMISSION_PRODUCT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_COMMISSION_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        commission_product: action.payload,
        message: action.payload,
      };
    case GET_COMMISSION_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Transactions_list_reducers
export const get_Transactions_list_Reducers = (
  state = { transaction_list: {} },
  action
) => {
  switch (action.type) {
    case GET_TRANSACTION_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        transaction_list: action.payload,
        message: action.payload,
      };
    case GET_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Reports_list_reducers
export const get_Reports_list_Reducers = (
  state = { reports_list: {} },
  action
) => {
  switch (action.type) {
    case GET_REPORTS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reports_list: action.payload,
        message: action.payload,
      };
    case GET_REPORTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// create_product_link_reducers
export const create_product_link_Reducers = (
  state = { product_link: {} },
  action
) => {
  switch (action.type) {
    case CREATE_PRODUCT_LINK_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PRODUCT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        product_link: action.payload,
        message: action.payload,
      };
    case CREATE_PRODUCT_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get_Product_reducers
export const get_Product_Reducers = (state = { product_info: {} }, action) => {
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      return {
        loading: true,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product_info: action.payload,
        message: action.payload,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Withdrawl_amount_reducers
export const withdraw_amount_Reducers = (
  state = { amount_withdrawl: {} },
  action
) => {
  switch (action.type) {
    case WITHDRAWL_AMOUNT_REQUEST:
      return {
        loading: true,
      };
    case WITHDRAWL_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        amount_withdrawl: action.payload,
        message: action.payload,
      };
    case WITHDRAWL_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get_categories_reducers
export const get_categories_Reducers = (
  state = { categories: {} },
  action
) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        message: action.payload,
      };
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get_Product_list_reducers
export const get_Products_list_Reducers = (
  state = { product_list_data: {} },
  action
) => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        product_list_data: action.payload,
        message: action.payload,
      };
    case GET_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
