import React from "react";
import "./SuccessModal.css";
import CheckIcon from "../../../Assets/Images/check_icon.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SuccessModal = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="success-details">
        <img src={CheckIcon} alt="" />
        <h1>Congratulations</h1>
        <p>
          Your account has been successfully created. You are now an official
          member of Hiscraves Affiliate Marketing. Please go to the dashboard to
          choose a product and start earning!
        </p>
        <Button onClick={() => navigate(`/`)}>Go to Dashboard</Button>
      </div>
    </>
  );
};

export default SuccessModal;
