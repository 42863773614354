export const routes = [
  {
    path: "/",
    title: "Dashboard",
    icon: require("../../Assets/Sidebar_Logo/home-hashtag.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/home-hashtag.png"),
  },
  {
    path: "/product-links",
    title: "Product Links",
    icon: require("../../Assets/Sidebar_Logo/link-square.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/link-square.png"),
  },
  {
    path: "/idea-hub",
    title: "Idea Hub",
    icon: require("../../Assets/Sidebar_Logo/category.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/category.png"),
  },
  {
    path: "/Commission",
    title: "Commission",
    icon: require("../../Assets/Sidebar_Logo/receipt-discount.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/category.png"),
  },
  {
    path: "/transactions",
    title: "Transactions",
    icon: require("../../Assets/Sidebar_Logo/convert-card.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/convert-card.png"),
  },
  {
    path: "/reports",
    title: "Reports",
    icon: require("../../Assets/Sidebar_Logo/document-text.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/document-text.png"),
  },
  {
    path: "/help",
    title: "Help",
    icon: require("../../Assets/Sidebar_Logo/info-circle.png"),
    iconColor: require("../../Assets/Sidebar_Logo/Color/info-circle.png"),
  },
];
